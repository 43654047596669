/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Access {
  ALTER_ALERTS = "ALTER_ALERTS",
  ALTER_APPS = "ALTER_APPS",
  ALTER_COMMISSIONS = "ALTER_COMMISSIONS",
  ALTER_ON_CALLS = "ALTER_ON_CALLS",
  ALTER_RISK_LEADS = "ALTER_RISK_LEADS",
  ALTER_STOCK_REQUESTS = "ALTER_STOCK_REQUESTS",
  ALTER_USERS = "ALTER_USERS",
  VIEW_COMMISSIONS = "VIEW_COMMISSIONS",
  VIEW_USERS = "VIEW_USERS",
}

export enum AlertStatus {
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
}

export enum CommissionStatus {
  AWAITING_COMPLETION = "AWAITING_COMPLETION",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
}

export enum GpcEquipmentType {
  APPLICATION = "APPLICATION",
  BOARDS = "BOARDS",
  EFK = "EFK",
  INSECTICIDES = "INSECTICIDES",
  INSECT_TRAPS = "INSECT_TRAPS",
  LURES = "LURES",
  PPE = "PPE",
  PROOFING = "PROOFING",
  RODENTICIDES = "RODENTICIDES",
  RODENT_BOXES = "RODENT_BOXES",
  TRAPS = "TRAPS",
}

export enum GpcLabourCostUnit {
  DAY = "DAY",
  HALF_DAY = "HALF_DAY",
  HOTEL = "HOTEL",
  HOUR = "HOUR",
}

export enum ObjectiveStatus {
  AMBER = "AMBER",
  GREEN = "GREEN",
  RED = "RED",
}

export enum OrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum QaFeedbackStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum Region {
  EAST_MIDLANDS = "EAST_MIDLANDS",
  EAST_OF_ENGLAND = "EAST_OF_ENGLAND",
  ENGLAND = "ENGLAND",
  IRELAND = "IRELAND",
  LONDON = "LONDON",
  NORTHERN_IRELAND = "NORTHERN_IRELAND",
  NORTH_EAST = "NORTH_EAST",
  NORTH_WALES = "NORTH_WALES",
  NORTH_WEST = "NORTH_WEST",
  SCOTLAND = "SCOTLAND",
  SOUTH_EAST = "SOUTH_EAST",
  SOUTH_WALES = "SOUTH_WALES",
  SOUTH_WEST = "SOUTH_WEST",
  WALES = "WALES",
  WEST_MIDLANDS = "WEST_MIDLANDS",
  YORKSHIRE = "YORKSHIRE",
}

export enum RiskLeadStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
  QUOTED = "QUOTED",
  SURVEYING = "SURVEYING",
}

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SUPER_ADMIN = "SUPER_ADMIN",
  USER = "USER",
}

export enum StockRequestStatus {
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  INFORMATION_REQUESTED = "INFORMATION_REQUESTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum TeamStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export interface ConvertPostcodesToLatLngInput {
  name: string;
  postcode: string;
}

export interface CreareKpiEarlyWarningInput {
  name: string;
  description?: string | null;
  contactId: number;
  metrics: any;
  categoryId: string;
}

export interface CreateAlertInput {
  title: string;
  body: string;
  authorId: string;
  date?: any | null;
  status: AlertStatus;
  pushNotify?: boolean | null;
}

export interface CreateAppInput {
  name: string;
  description?: string | null;
  url: string;
  username?: string | null;
  password?: string | null;
}

export interface CreateCommissionInput {
  quoteId?: number | null;
  reference?: string | null;
  riskLeadReference?: string | null;
  client: string;
  userIds?: string[] | null;
  totalExVat: number;
  notes?: string | null;
  technicianNotes?: string | null;
  status?: CommissionStatus | null;
  createdAt?: any | null;
}

export interface CreateCommissionPaymentInput {
  type: string;
  total: number;
  date?: any | null;
  startDate?: any | null;
  totalMonths?: number | null;
  userId: string;
}

export interface CreateEditGpcEquipmentInput {
  name?: string | null;
  code?: string | null;
  type?: GpcEquipmentType | null;
  costPrice?: number | null;
  sellingPrice?: number | null;
  notes?: string | null;
}

export interface CreateEditLabourCostInput {
  name?: string | null;
  unit?: GpcLabourCostUnit | null;
  rate?: number | null;
}

export interface CreateOnCallInput {
  startDate: any;
  endDate: any;
  userIds: string[];
}

export interface CreateOrUpdateSettingInput {
  name: string;
  value: string;
}

export interface CreateProductCategoryInput {
  name: string;
}

export interface CreateProductInput {
  name: string;
  categoryId: string;
  make?: string | null;
  price: number;
}

export interface CreateRiskLeadInput {
  jobReference: string;
  jobType: string;
  userId: string;
  client: string;
  jobUrl?: string | null;
  status: RiskLeadStatus;
  date: any;
}

export interface CreateStockRequestInput {
  userId?: string | null;
  products: StockRequestItem[];
}

export interface CreateUpdateObjectiveInput {
  userId?: string | null;
  teamId?: string | null;
  title: string;
  description: string;
  status: ObjectiveStatus;
  isPrivate: boolean;
  priority?: number | null;
  subObjectives?: CreateUpdateSubObjectiveInput[] | null;
  completed?: boolean | null;
}

export interface CreateUpdateSubObjectiveInput {
  id?: string | null;
  title: string;
  completed: boolean;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role?: Role | null;
  region?: Region | null;
  profileImageUrl?: string | null;
  jobTitle?: string | null;
  phoneNumber?: string | null;
  resourceReference?: string | null;
  resourceLabel?: string | null;
}

export interface EditUserInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  password?: string | null;
  role?: Role | null;
  region?: Region | null;
  profileImageUrl?: string | null;
  jobTitle?: string | null;
  phoneNumber?: string | null;
  teamIds?: string[] | null;
  contactEnabled?: boolean | null;
  resourceReference?: string | null;
  resourceLabel?: string | null;
  revenueTarget?: number | null;
  access?: Access[] | null;
}

export interface ImportCommissionInput {
  reference: string;
  riskLeadReference?: string | null;
  client: string;
  userLabels?: string[] | null;
  totalExVat: string;
  datePaid: any;
}

export interface OrderBy {
  field: string;
  direction: OrderByDirection;
}

export interface QaFeedbackInput {
  jobId?: string | null;
  rating: number;
  comment?: string | null;
}

export interface StockRequestItem {
  productId: string;
  quantity: number;
}

export interface UpdateAlertInput {
  id: string;
  title: string;
  body: string;
  authorId: string;
  date?: any | null;
  status: AlertStatus;
}

export interface UpdateAppInput {
  id: string;
  name: string;
  description?: string | null;
  url: string;
  username?: string | null;
  password?: string | null;
}

export interface UpdateCommissionInput {
  quoteId?: number | null;
  reference?: string | null;
  riskLeadReference?: string | null;
  client?: string | null;
  userIds?: string[] | null;
  totalExVat?: number | null;
  notes?: string | null;
  technicianNotes?: string | null;
  status?: CommissionStatus | null;
}

export interface UpdateRiskLeadInput {
  id: string;
  jobReference: string;
  jobType: string;
  userId: string;
  client: string;
  status: RiskLeadStatus;
  notes?: string | null;
  date: any;
}

export interface UpdateStockRequestInput {
  userId?: string | null;
  products: StockRequestItem[];
  status: StockRequestStatus;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
