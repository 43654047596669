import gql from 'graphql-tag';

export const RISK_LEADS = gql`
  query RiskLeads($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String, $userId: String, $status: RiskLeadStatus) {
    riskLeads(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup, userId: $userId, status: $status) {
      total
      items {
        id
        jobId
        jobReference
        jobType
        userId
        user {
          id
          firstName
          lastName
        }
        teamId
        client
        status
        date
        createdAt
        updatedAt
      }
    }
  }
`;

export const RISK_LEAD = gql`
  query RiskLead($id: ID!) {
    riskLead(id: $id) {
      id
      jobId
      jobReference
      jobType
      userId
      user {
        id
        firstName
        lastName
      }
      teamId
      client
      status
      date
      notes
      createdAt
      updatedAt
    }
  }
`;
